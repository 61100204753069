import { defineMessages } from '@sevenrooms/core/locales'

export const shiftMessages = defineMessages({
  overrideShiftNameHeader: {
    id: 'shift.view.overrideShiftNameHeader',
    defaultMessage: 'Shift Name',
  },
  overrideScheduleHeader: {
    id: 'shift.view.overrideScheduleHeader',
    defaultMessage: 'Schedule',
  },
  overrideShiftCategoryHeader: {
    id: 'shift.view.overrideShiftCategoryHeader',
    defaultMessage: 'Shift Categories',
  },
  overridePartySizeHeader: {
    id: 'shift.view.overridePartySizeHeader',
    defaultMessage: 'Party Size',
  },
  overrideEditSettingsHeader: {
    id: 'shift.view.overrideEditSettingsHeader',
    defaultMessage: 'Edit Settings',
  },
  overrideDateAtTimes: {
    id: 'shift.view.overrideDateAndTimes',
    defaultMessage: `{endDate, select,
      indefinite {
        {date} - indefinitely
      }
      null {
        {date} from {startTime} to {endTime}
      }
      other {
        {date} - {endDate}
      }
    }`,
  },
} as const)
