import { defineMessages } from '@sevenrooms/core/locales'

export const ExportsLocales = defineMessages({
  noChanges: {
    id: 'exports.noChanges',
    defaultMessage: 'No changes',
  },
  reviewChangesHeaderText: {
    id: 'exports.reviewChangesHeaderText',
    defaultMessage: 'You are editing:',
  },
  reviewOverrideHeaderText: {
    id: 'exports.reviewOverrideHeaderText',
    defaultMessage: 'You edited:',
  },
} as const)
