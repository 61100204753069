import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Table, TableBody, TableRow, TableCell } from '@sevenrooms/core/ui-kit/layout'
import { Link } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { shiftMessages } from './Shift.locales'

/* eslint-disable camelcase */
export interface ShiftOverridesProps {
  overrides: {
    id: string
    persistent_id: string
    name: string
    date: string
    end_date?: string
    is_indefinite: boolean
    start_time: string
    end_time: string
    category: string
  }[]
}
/* eslint-enable camelcase */

export function ShiftOverrides({ overrides }: ShiftOverridesProps) {
  const { formatMessage } = useLocales()
  const { venueUrlKey } = useAppContext()

  return (
    <Table>
      <thead>
        <tr>
          <TableCell isHeader isInFirstRow>
            {formatMessage(shiftMessages.overrideShiftNameHeader)}
          </TableCell>
          <TableCell isHeader isInFirstRow>
            {formatMessage(shiftMessages.overrideScheduleHeader)}
          </TableCell>
          <TableCell isHeader isInFirstRow>
            {formatMessage(shiftMessages.overrideShiftCategoryHeader)}
          </TableCell>
          <TableCell isHeader isInFirstRow>
            {formatMessage(shiftMessages.overrideEditSettingsHeader)}
          </TableCell>
        </tr>
      </thead>

      <TableBody>
        {overrides.map(shift => (
          <TableRow key={shift.id}>
            <TableCell>{shift.name}</TableCell>
            <TableCell>{`${formatMessage(shiftMessages.overrideDateAtTimes, {
              date: DateOnly.from(shift.date).formatNYearNMonthNDay(),
              startTime: TimeOnly.from(shift.start_time).formatSTime(),
              endTime: TimeOnly.from(shift.end_time).formatSTime(),
              endDate: shift.is_indefinite ? 'indefinite' : DateOnly.fromSafe(shift.end_date)?.formatNYearNMonthNDay() ?? 'null',
            })}`}</TableCell>
            <TableCell>{getShiftCategoryName(shift.category)}</TableCell>
            <TableCell>
              <Link
                target="_blank"
                to={
                  `/manager/${venueUrlKey}/manage/capacity/schedule` +
                  `?selected_shift_persistent_id=${shift.persistent_id}&date=${DateOnly.from(shift.date).formatNYearNMonthNDay()}`
                }
              >
                <Icon name="VMSWeb-open-in-new" />
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export function getShiftCategoryName(category: string) {
  const [first, ...rest] = category === 'LEGACY' ? 'night'.toLocaleLowerCase() : category.toLocaleLowerCase()
  return [first?.toLocaleUpperCase(), ...rest].join('')
}
